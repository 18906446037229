import Alpine from 'alpinejs'
import {Editor} from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import {Underline} from "@tiptap/extension-underline";

import {tns} from "tiny-slider";

Array.from(document.querySelectorAll('.quick-items.carousel')).forEach((carouselContainer) => {
    const controls =
        carouselContainer.parentNode.querySelector(".quick-items-controls") !== null > 0 ? {
            nextButton: carouselContainer.parentNode.querySelector(".quick-items-next"),
            prevButton: carouselContainer.parentNode.querySelector(".quick-items-prev")
        } : {};

    tns({
        container: carouselContainer,
        controls: true,
        items: window.innerWidth < 768 ? 3 : 5,
        loop: false,
        gutter: 10,
        ...controls
    });
})


require('./bootstrap');

window.setupEditor = function (content) {
    return {
        editor: null,
        content: content,
        updatedAt: Date.now(), // force Alpine to rerender on selection change
        init(element) {
            this.editor = new Editor({
                element: element,
                extensions: [
                    StarterKit,
                    Underline
                ],
                content: this.content,
                onUpdate: ({editor}) => {
                    this.content = editor.getHTML()
                },
                onSelectionUpdate: () => {
                    this.updatedAt = Date.now()
                },
            })
        },
    }
}

document.addEventListener('livewire:load', function () {
    window.Alpine = Alpine
    Alpine.start()
})
